@import 'src/styles/variables';

.label {
  color: $black80;
  margin-bottom: 24px;
}

.input {
  margin-bottom: 24px;
}

.button {
  margin-top: 24px;
}
