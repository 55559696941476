@import 'src/styles/mixins';
@import 'src/styles/variables';

.loyaltyContainer {
  @include stepsContainer;
  background: $white;
  padding: 20px;
  border-radius: 24px;
  margin-top: 16px !important;
  display: flex;
  gap: 24px;
  align-items: center;

  .message {
    width: 100%;
  }

  .gift {
    width: 42px;
  }
}

.buttonContainer {
  @include verticalGap(20px);
}
