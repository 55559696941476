@import 'src/styles/variables';

.navigation {
  display: flex;
  justify-content: center;
}

.step {
  border-radius: 16px 16px 0 0;
  border-top: 1px;
  border-left: 1px;
  border-right: 1px;
  border-color: #eeeeee;
  padding: 13px 18px;
  z-index: 1;
  height: 24px;
  cursor: pointer;
  position: relative;
}

.step.active {
  background-color: $white;
}

.icon {
  width: 24px;
  height: 24px;
  opacity: 0.5;
}

.icon.completed {
  opacity: 1;

  & path {
    fill: $green;
  }
}

.active .icon {
  opacity: 1;
}

.check {
  position: absolute;
  top: 5px;
  right: 6px;
  width: 14px;
  height: 14px;
}
