@import 'styles/variables';
@import 'styles/mixins';

html,
body,
#root {
  height: 100%;
}

@font-face {
  font-weight: $thinFontWeight;
  font-family: 'Poppins';
  src: url('fonts/Poppins/Poppins-Thin.ttf') format('truetype');
}

@font-face {
  font-weight: $thinFontWeight;
  font-family: 'Poppins';
  font-style: italic;
  src: url('fonts/Poppins/Poppins-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-weight: $extraLightFontWeight;
  font-family: 'Poppins';
  src: url('fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-weight: $extraLightFontWeight;
  font-family: 'Poppins';
  font-style: italic;
  src: url('fonts/Poppins/Poppins-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-weight: $lightFontWeight;
  font-family: 'Poppins';
  src: url('fonts/Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-weight: $lightFontWeight;
  font-family: 'Poppins';
  font-style: italic;
  src: url('fonts/Poppins/Poppins-LightItalic.ttf') format('truetype');
}

@font-face {
  font-weight: $regularFontWeight;
  font-family: 'Poppins';
  src: url('fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-weight: $regularFontWeight;
  font-family: 'Poppins';
  font-style: italic;
  src: url('fonts/Poppins/Poppins-Italic.ttf') format('truetype');
}

@font-face {
  font-weight: $mediumFontWeight;
  font-family: 'Poppins';
  src: url('fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-weight: $mediumFontWeight;
  font-family: 'Poppins';
  font-style: italic;
  src: url('fonts/Poppins/Poppins-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-weight: $semiBoldFontWeight;
  font-family: 'Poppins';
  src: url('fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-weight: $semiBoldFontWeight;
  font-family: 'Poppins';
  font-style: italic;
  src: url('fonts/Poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-weight: $boldFontWeight;
  font-family: 'Poppins';
  src: url('fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-weight: $boldFontWeight;
  font-family: 'Poppins';
  font-style: italic;
  src: url('fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-weight: $extraBoldFontWeight;
  font-family: 'Poppins';
  src: url('fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-weight: $extraBoldFontWeight;
  font-family: 'Poppins';
  font-style: italic;
  src: url('fonts/Poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-weight: $blackFontWeight;
  font-family: 'Poppins';
  src: url('fonts/Poppins/Poppins-Black.ttf') format('truetype');
}

@font-face {
  font-weight: $blackFontWeight;
  font-family: 'Poppins';
  font-style: italic;
  src: url('fonts/Poppins/Poppins-BlackItalic.ttf') format('truetype');
}

/* stylelint-disable selector-list-comma-newline-after */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  border: 0;
}

/* stylelint-enable selector-list-comma-newline-after */

body {
  font-family: $poppinsFontStyle;
}

.anchor {
  color: $purple300;
  font-weight: 600;
  text-decoration: underline;
}

// Overrides toastify styling
.Toastify__toast-container {
  @include sm {
    display: inline-flex;
    flex: none;
    width: auto;
    max-width: 500px;
  }
}

.Toastify__toast {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background-color: $black80;
  color: $white;
  border-radius: 4px;
  margin: 16px;
  font-weight: $mediumFontWeight;
  font-size: 14px;
  line-height: 24px;

  @include sm {
    margin: 0;
  }
}

.alloy-iframe {
  animation: fade 0.3s linear !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: $primaryColor;
  color: $white !important;
}

.disableSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
