@import 'src/styles/variables';
@import 'src/styles/mixins';

.reviewContainer {
  @include cardContainer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.reviewTitle {
  font-weight: $boldFontWeight;
  margin-bottom: 24px;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.reviewIcon {
  flex-shrink: 0;
  width: 48px;
  height: 48px;
}

.reviewText {
  font-size: 14px;
  font-weight: 400;
}
