@import 'src/styles/mixins';
@import 'src/styles/variables';

.backLink {
  margin-bottom: 8px;
}

.container {
  @include stepsContainer;
  text-align: center;
}

.title {
  @include h2;
  margin-bottom: 24px;
}

.subtitle {
  margin-bottom: 36px;
}

.resourceList {
  margin-bottom: 36px;
}

.resourceName {
  color: $primaryColor;
  font-weight: 500;
  display: block;
  text-decoration: none;
}
